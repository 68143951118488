<template>
  <div>
    <el-dialog v-model="view" :close-on-click-modal="false" :close-on-press-escape="false" title="奖品设置" width="900px">
      <el-button type="primary" icon="el-icon-plus" class="add" @click="openAdd">添加</el-button>
      <el-table
          :data="tableData"
          border
          class="table"
          header-cell-class-name="table-header"
          size="small"
          height="500"
      >
        <el-table-column prop="titleNumber" label="项目编号"></el-table-column>
        <el-table-column prop="Image" label="中奖图片" width="180">
          <template #default="scope">
            <el-image
                class="Image"
                :src="scope.row.Image"
                :preview-src-list="[scope.row.Image]">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="ImageID" label="图片编号"></el-table-column>
        <el-table-column prop="NewTime" label="新增时间"></el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-button
                type="text"
                class="red"
                @click="removeImage(scope.row.id)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog v-model="addView" :close-on-click-modal="false" :close-on-press-escape="false" width="500px">
      <el-form ref="form" label-width="50px">
        <el-form-item label="编号">
          <el-input type="text" v-model="form.ImageID"/>
        </el-form-item>
        <el-form-item>
          <el-upload
              class="upload-block"
              accept="image/png,image/jpg,image/jpeg"
              :action='actionUrl'
              :show-file-list="false"
              :on-success="handleSuccess">
            <el-button type="default">上传</el-button>
          </el-upload>
          <el-image
              class="avatar"
              v-if="form.Image"
              :src="form.Image"
              :preview-src-list="[form.Image]">
          </el-image>
        </el-form-item>
      </el-form>
      <template #footer>
                  <span class="dialog-footer">
           <el-button @click="Imageclose">取 消</el-button>
           <el-button type="primary" @click="saveImageAdd">确定</el-button>
    </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {removeImage,AddImage, findImage} from "../../api/Prize";
import {Action} from "../../api/CanvasAdmin";

export default {
  data() {
    return {
      view: false,
      addView: false,
      tableData: [],
      actionUrl: '',
      form: {
        ImageID: '',
        Image: ''
      }
    }
  },
  mounted() {
    this.actionUrl = Action()
  },
  methods: {
    removeImage(id) {
      this.$confirm("是否确认删除吗？", "提示", {
        type: "warning"
      }).then(() => {
        removeImage({id}).then(res=>{
          if (res.code == 0) {
            this.fachdata()
            this.$message.success('删除成功');
          } else {
            this.$message.error('删除失败');
          }
        })
      })
    },
    saveImageAdd() {
      let ImageID = this.form.ImageID
      let Image = this.form.Image
      AddImage({ImageID, Image}).then(res => {
        if (res.code == 0) {
          this.Imageclose()
          this.fachdata()
          this.$message.success('添加成功');
        } else {
          this.$message.error(res.message);
        }
      })
    },
    Imageclose() {
      this.form.ImageID = ''
      this.form.Image = ''
      this.addView = false
    },
    fachdata() {
      findImage().then(res => {
        let data = res.data
        this.tableData = data
      })
    },
    open() {
      this.view = true
      this.fachdata()
    },
    openAdd() {
      this.addView = true
    },
    handleSuccess(res) {
      this.form.Image = res.imgfile;
    },
  }
}
</script>

<style scoped lang="scss">
.add {
  margin-bottom: 20px;
}

.red {
  color: red;
}

.Image {
  width: 80px;
}

.upload-block {
  ::v-deep .el-upload--text {
    width: auto;
    height: auto;
    border: none;
  }
}
</style>
