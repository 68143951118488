<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 点灯顺序管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="handle-box">
      <el-button type="primary" icon="el-icon-plus" @click="openadd">添加</el-button>
    </div>
    <div class="container">
      <el-table
          :data="tableData"
          border
          height="500"
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          size="small"
      >
        <el-table-column prop="titleNumber" label="项目" width="180"></el-table-column>
        <el-table-column prop="Light" label="点亮灯数"></el-table-column>
        <el-table-column prop="Number" label="作画数量"></el-table-column>
        <el-table-column prop="NewTime" label="新增时间"></el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-button class="red" type="text" @click="removeClose(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog v-model="view.add" :close-on-click-modal="false" :close-on-press-escape="false"
               :show-close="false" width="500px" title="添加">
      <el-form label-width="80px">
        <el-form-item label="点亮灯数" :required="true">
          <el-input v-model="form.Light"></el-input>
        </el-form-item>
        <el-form-item label="作画数量" :required="true">
          <el-input v-model="form.Number"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
    <span class="dialog-footer">
           <el-button @click="closeView">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">添加</el-button>
    </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {adds, find, removes} from '../../api/Lamp'

export default {
  data() {
    return {
      tableData: [],
      view: {
        add: false
      },
      form: {
        Light: '',
        Number: ''
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      find().then(res => {
        let data = res.data
        this.tableData = data
      })
    },
    openadd() {
      this.view.add = true
      this.closeform()
    },
    closeform() {
      this.form.Light = ''
      this.form.Number = ''
    },
    closeView() {
      this.view.add = false
      this.closeform()
    },
    saveEdit() {
      let Light = this.form.Light
      let Number = this.form.Number
      adds({Light, Number}).then(res => {
        if (res.code == 0) {
          this.$message.success(res.message)
          this.closeView()
          this.fetchData()
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    removeClose(id) {
      console.log(id)
      this.$confirm("是否确认删除吗？", "提示", {
        type: "warning"
      }).then(() => {
        removes({id}).then(res => {
          if (res.code == 0) {
            this.$message.success(res.message)
            this.closeView()
            this.fetchData()
          } else {
            this.$message.warning(res.message)
          }
        })
      })

    }
  }
}
</script>

<style scoped lang="scss">
.red {
  color: red;
}

.handle-box {
  margin-bottom: 20px;
}

</style>