<template>
  <div>
    <el-dialog v-model="view" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false"
               width="1000px" title="设置效果图">
      <el-form label-width="120px">
        <el-form-item label="上传图片" :required="true">
          <el-image
              class="avatar"
              v-if="form.genBgUrl"
              :src="form.genBgUrl"
              :preview-src-list="[form.genBgUrl]">
          </el-image>
        </el-form-item>
        <el-form-item>
          <p class="green" v-if="form.genBgUrl && !start"><i class="el-icon-circle-check"></i>上传成功</p>
          <el-upload
              v-if="!start"
              class="upload-block"
              accept="image/png,image/jpg,image/jpeg"
              :action='actionUrl'
              :show-file-list="false"
              :on-success="handleSuccess"
              :before-upload="beforeUpload">
            <el-button type="default">上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="是否可见">
          <el-switch
              v-model="form.genStart"
              active-color="#13ce66"
              inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
      </el-form>
      <template #footer>
    <span class="dialog-footer">
           <el-button @click="close">取 消</el-button>
           <el-button type="primary" @click="saveEdit">确定</el-button>
    </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {Action, genBgUrlset, genBgUrlfind} from "../../api/CanvasAdmin";

export default {
  data() {
    return {
      view: false,
      start: false,
      titleNumber: '',
      form: {
        genBgUrl: '',
        genStart: false
      },
      actionUrl: ''
    }
  },
  mounted() {
    this.actionUrl = Action()
  },
  methods: {
    saveEdit() {
      let genBgUrl = this.form.genBgUrl
      let genStart = this.form.genStart
      let titleNumber = this.titleNumber
      genBgUrlset({genBgUrl, genStart, titleNumber}).then(res => {
        if (res.code == 0) {
          this.$message.success(res.message)
          this.close()
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    getdata() {
      let titleNumber = this.titleNumber
      genBgUrlfind({titleNumber}).then(res => {
        if (res.code == 0) {
          let data = res.data
          this.form.genBgUrl = data.genBgUrl
          this.form.genStart = Boolean(data.genStart)
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    open(titleNumber) {
      this.view = true
      this.titleNumber = titleNumber
      this.getdata()
    },
    close() {
      this.form = {}
      this.titleNumber = ''
      this.view = false
    },
    handleSuccess(res) {
      this.form.genBgUrl = res.imgfile;
    },
    beforeUpload() {

    }
  }

}
</script>

<style scoped lang="scss">
.avatar {
  width: 500px;

}

.green {
  color: #00a854;
}

.upload-block {
  ::v-deep .el-upload--text {
    width: auto;
    height: auto;
    border: none;
  }
}
</style>
