<template>
  <div>
    <el-dialog v-model="view.list" :close-on-click-modal="false" :close-on-press-escape="false"
               width="900px" title="画布列表">
      <el-table
          v-loading="loading"
          :data="tableData"
          height="450"
          border
      > 
        <el-table-column prop="OrderNumber" width="180" label="序号"></el-table-column>
        <el-table-column prop="SidNumber" width="180" label="创作编号"></el-table-column>
        <el-table-column prop="name" width="100" label="笔名"></el-table-column>
        <el-table-column prop="penName" width="100" label="作品昵称"></el-table-column>
        <el-table-column prop="imageUrl" width="120" label="创作图片">
          <template #default="scope">
            <el-image
                style=" height: 50px"
                :src="scope.row.imageUrl"
                :preview-src-list="[scope.row.imageUrl]">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column width="100">
          <template #default="scope">
            <el-button
                type="text"
                class="green"
                v-if=" !scope.row.start"
                @click="setshow( scope.row._id)"
            >显示
            </el-button>
            <el-button
                type="text"
                class="yellow"
                v-else
                @click="setshow( scope.row._id)"
            >不显示
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="user_id" width="100" label="账号ID" :show-overflow-tooltip='true'></el-table-column>
        <el-table-column prop="time" width="170" label="完成创作时间"></el-table-column>
        <el-table-column prop="chainHash" width="150" label="哈系数" :show-overflow-tooltip='true'></el-table-column>
        <el-table-column prop="chainevId" width="150" label="法院证据" :show-overflow-tooltip='true'></el-table-column>
        <el-table-column prop="chainNumber" width="150" label="存证编号" :show-overflow-tooltip='true'></el-table-column>
        <el-table-column prop="collect" width="100" label="点赞"></el-table-column>
        <el-table-column prop="collectNot" width="100" label="不喜欢"></el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            v-if="view.start"
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="query.pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {listData, operate} from '../../api/CanvasAdmin'

export default {
  data() {
    return {
      loading: false,
      titleNumber: '',
      query: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0
      },
      view: {
        list: false,
        //是否是已创作状态
        start: true
      },
      tableData: [],
    }
  },
  methods: {
    setshow(_id) {
      this.$confirm("确定要执行该操作吗？", "提示", {
        type: "warning",
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        operate({_id}).then(res => {
          if (res.code == 0) {
            this.getData();
            this.$message.success(res.message)
          } else {
            this.$message.warning(res.message)
          }
        })
      })
    },
    handlePageChange(val) {
      this.query.pageIndex = val
      this.getData()
    },
    closeView() {
      this.view.list = false
    },
    openlist(titleNumber) {
      this.titleNumber = titleNumber
      this.getData()
      this.view.list = true
    },
    getData() {
      let skip = this.query.pageIndex
      let titleNumber = this.titleNumber
      this.tableData = []
      this.loading = true
      listData({titleNumber, skip}).then(res => {
        this.loading = false
        let sum = res.sum
        let number = res.number

        this.tableData = sum
        this.query.pageTotal = number
      })
    }
  }
}
</script>

<style scoped lang="scss">
.avatar {
  width: 80px;
}

.handle-box {
  margin-bottom: 10px;
}

.green {
  color: #00a854;
}

.yellow {
  color: rgba(196, 154, 12, 0.81);
}
</style>
