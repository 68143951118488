<template>
  <div class="CanvasMap" v-if="view.map">
    <div class="main" >
      <canvas id="gameCanvas" width="500" height="300" ref="Handle"></canvas>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      view: {
        map: false
      },
      // 记录矩形的位置
      pos: {
        x: 0,
        y: 0,
        width: 100,
        height: 100
      },
      posStart: ''
    }
  },


  mounted() {
    this.el = this.$refs.Handle
    // this.init()
  },
  methods: {
      init(){
        var cvs = document.getElementById('gameCanvas');
        var ctx = cvs.getContext('2d');
//绘制黑色背景
        ctx.fillStyle = 'black';
        ctx.fillRect(0, 0, 500,300);
//绘制红色矩形
        ctx.fillStyle = 'red';
        ctx.fillRect(100, 100, 100,100);
//记录矩形的位置
        var posX = 100;
        var posY = 100;

        cvs.onmousedown = function(e){

          var x = e.clientX - posX;
          var y = e.clientY - posY;

          //判断鼠标是否点击在矩形内
          if(e.clientX >= posX + this.offsetLeft && e.clientX <= posX + 100 + this.offsetLeft && e.clientY >= posY + this.offsetTop && e.clientY <= posY + 100 + this.offsetTop){

            document.onmousemove = function(e){
              //ctx.clearRect(posX, posY, 100,100);

              //鼠标拖动时重绘黑色背景
              ctx.fillStyle = 'black';
              ctx.fillRect(0, 0, 500,300);
              //鼠标拖动时重绘红色矩形
              ctx.fillStyle = 'red';
              ctx.fillRect(e.clientX-x, e.clientY-y, 100,100);
              //记录矩形的位置
              posX = e.clientX-x;
              posY = e.clientY-y;
            };
            document.onmouseup = function(){
              document.onmousemove = null;
            };
          }
        };
      }
  }
}
</script>

<style scoped lang="scss">
#sign {

}

.CanvasMap {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;

  .main {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    position: absolute;
  }
}
</style>
