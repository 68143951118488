import request from '../utils/request';

//查询模糊用户内容
export const regexfetchData = query => {
    return request({
        url: process.env.VUE_APP_URL+'user/admin/find/regex',
        method: 'get',
        params: query
    });
};


//查询用户内容
export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL+'user/admin/find',
        method: 'get',
        params: query
    });
};


//查询用户作品
export const fetchUserData = query => {
    return request({
        url: process.env.VUE_APP_URL+'user/admin/work/user',
        method: 'get',
        params: query
    });
};




