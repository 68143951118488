<template>
  <div class="header">
<!--    <div class="logo"></div>-->
<!--    <div class="title">失控实验后台管理系统</div>-->
    <div class="header-right">
      <div class="header-user-con">
        <!-- 用户头像 -->
        <div class="user-avator">
          <!--          <img src="../assets/img/img.jpg"/>-->
        </div>
        <!-- 用户名下拉菜单 -->
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                        {{ $store.state.UserInfo.Name }}
                        <i class="el-icon-caret-bottom"></i>
                    </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>

import {fetchData} from "../api/Header";

export default {
  data() {
    return {
      fullscreen: false,
      name: "linxin",
      message: 2
    };
  },
  computed: {
    collapse() {
      return this.$store.state.collapse;
    }
  },
  created() {
    let UserInfo=this.$store.state.UserInfo
    if (!UserInfo.Name){
      localStorage.removeItem("UserInfo");
      this.$router.push("/login");
      this.$store.state.UserInfo = {}
    }
  },
  methods: {
    // 用户名下拉菜单选择事件
    handleCommand(command) {
      if (command == "loginout") {
        localStorage.removeItem("UserInfo");
        this.$router.push("/login");
        this.$store.state.UserInfo = {}
      }
    },
    handfind() {
      let _id = localStorage.getItem("User_id");
      if (_id) {
        fetchData({_id}).then(res => {
          let UserInfo = res.sum[0]
          this.$store.state.UserInfo = UserInfo
          this.$store.commit('UserInfoTitle')    // 这就是调用的方法
        })
      }
    }

  },
};
</script>
<style scoped>
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  font-size: 22px;
  color: #000;
  background-color: #f5f5f5;
  border-bottom: 1px solid #aaaaaa;
}

.collapse-btn {
  float: left;
  padding: 0 21px;
  cursor: pointer;
  line-height: 70px;
}

.header .logo {
  float: left;
  width: 70px;
  height: 70px;
  background-image: url("../../public/logo.png");
  background-position: center center;
  background-size: 30px 30px;
  background-repeat: no-repeat;
}

.header .title {
  float: left;
  font-size: 15px;
  height: 70px;
  line-height: 70px;
}

.header .title h3 {
  display: inline;
  margin-right: 20px;
}


.header-right {
  float: right;
  padding-right: 50px;
}

.header-user-con {
  display: flex;
  height: 70px;
  align-items: center;
}

.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}

.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}

.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #fff;
}

.btn-bell .el-icon-bell {
  color: #fff;
}

.user-name {
  margin-left: 10px;
}

.user-avator {
  margin-left: 20px;
}

.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.el-dropdown-link {
  color: #000;
  cursor: pointer;
}

.el-dropdown-menu__item {
  text-align: center;
}
</style>
