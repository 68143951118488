<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card shadow="hover" class="mgb20" style="height:200px;">
          <div class="user-info">
            <img src="../assets/img/img.jpg" class="user-avator" alt/>
            <div class="user-info-cont">
              <div class="user-info-name">欢迎你归来~{{ $store.state.UserInfo.Name }}</div>
              <div>权限：{{ $store.state.UserInfo.Mode }}</div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="16">
        <el-row :gutter="20" class="mgb20">
          <el-col :span="8">
            <el-card shadow="hover" :body-style="{ padding: '0px' }">
              <div class="grid-content grid-con-1">
                <i class="el-icon-discount grid-con-icon"></i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{ InfoSum.HomeNumber }}</div>
                  <div>总访问量</div>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card shadow="hover" :body-style="{ padding: '0px' }">
              <div class="grid-content grid-con-2">
                <i class="el-icon-circle-check grid-con-icon"></i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{ InfoSum.userNumber }}</div>
                  <div>用户量</div>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card shadow="hover" :body-style="{ padding: '0px' }">
              <div class="grid-content grid-con-3">
                <i class="el-icon-circle-plus-outline grid-con-icon"></i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{ InfoSum.workNumber }}</div>
                  <div>创作数量</div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-card shadow="hover">
              <schart ref="line" class="schart" canvasId="line" :options="Pvoptions"></schart>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-card shadow="hover">
              <schart ref="bar" class="schart" canvasId="bar" :options="Rankingoptions"></schart>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Schart from "vue-schart";
import {Homeinit} from "../api/Dashboard";


export default {
  components: {Schart},
  data() {
    return {
      name: localStorage.getItem("ms_username"),
      Pvoptions: [],
      Rankingoptions: [],
      InfoSum: {
        HomeNumber: 0,
        workNumber: 0,
        userNumber: 0
      }
    };
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      Homeinit().then(res => {
        let InfoSum = res.sum
        let sharingTime = InfoSum.sharingTime
        this.sharingTimeData(sharingTime)
        this.InfoSum = InfoSum
      })
    },
    sharingTimeData(str) {
      let labels = []
      let data = []

      for (let item in str) {
        let Time = str[item].TimeMonth + '月' + str[item].TimeDate + "日" + str[item].TimeHours + "时"
        let Number = str[item].Number
        labels.push(Time)
        data.push(Number)
      }
      labels.reverse();
      data.reverse()

      let options = {
        type: "line",
        title: {
          text: "网站访问量",
        },
        labels: labels,
        datasets: [
          {
            label: "pv",
            data: data,
          },
        ],
      };
      this.Pvoptions = options
    },
  }
};
</script>

<style scoped>

.el-row {
  margin-bottom: 20px;
}

.grid-content {
  display: flex;
  align-items: center;
  height: 100px;
}

.grid-cont-right {
  flex: 1;
  text-align: center;
  font-size: 14px;
  color: #999;
}

.grid-num {
  font-size: 30px;
  font-weight: bold;
}

.grid-con-icon {
  font-size: 50px;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  color: #fff;
}

.grid-con-1 .grid-con-icon {
  background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
  color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
  background: rgb(100, 213, 114);
}


.grid-con-2 .grid-num {
  color: rgb(100, 213, 114);
}

.grid-con-3 .grid-con-icon {
  background: #ffd11e;
}

.grid-con-3 .grid-num {
  color: rgb(242, 94, 67);
}

.user-info {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
}

.user-avator {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.user-info-cont {
  padding-left: 50px;
  flex: 1;
  font-size: 12px;
  color: #999;
}

.user-info-cont div:first-child {
  font-size: 17px;
  color: #222;
}

.user-info-list {
  font-size: 14px;
  color: #999;
  line-height: 25px;
}

.user-info-list span {
  margin-left: 70px;
}

.mgb20 {
  margin-bottom: 20px;
}

.todo-item {
  font-size: 14px;
}

.todo-item-del {
  text-decoration: line-through;
  color: #999;
}

.schart {
  width: 100%;
  height: 300px;
}
</style>
