import {createRouter, createWebHistory} from "vue-router";
import Home from "../views/Home.vue";

const routes = [
    {
        path: '/',
        redirect: '/dashboard'
    }, {
        path: "/",
        name: "Home",
        component: Home,
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                meta: {
                    title: '系统首页'
                },
                component: () => import ("../views/Dashboard.vue")
            }, {
                path: '/404',
                name: '404',
                meta: {
                    title: '找不到页面'
                },
                component: () => import ('../views/404.vue')
            }, {
                path: '/403',
                name: '403',
                meta: {
                    title: '没有权限'
                },
                component: () => import ('../views/403.vue')
            },
            {
                path: '/User/index',
                meta: {
                    title: '用户管理'
                },
                component: () => import ('../views/User/index')

            },
            {
                path: '/CanvasAdmin/index',
                meta: {
                    title: '画布管理'
                },
                component: () => import ('../views/CanvasAdmin/index')
            },
            {
                path: '/ActivityAdmin/Admin',
                meta: {
                    title: '后台管理员管理'
                },
                component: () => import ('../views/ActivityAdmin/Admin')
            },
            {
                path: '/ActivityAdmin/PermissionRouter',
                meta: {
                    title: '资源管理'
                },
                component: () => import ('../views/ActivityAdmin/PermissionRouter')
            },
            {
                path: '/ActivityAdmin/RoleRoot',
                meta: {
                    title: '角色管理'
                },
                component: () => import ('../views/ActivityAdmin/RoleRoot')
            },
            {
                path: '/CommentAdmin/index',
                meta: {
                    title: '弹幕管理'
                },
                component: () => import ('../views/CommentAdmin/index')
            },
            {
                path: '/SensitiveAdmin/index',
                meta: {
                    title: '敏感词管理'
                },
                component: () => import ('../views/SensitiveAdmin/index')
            },
            {
                path: '/Lamp/index',
                meta: {
                    title: '点灯顺序管理'
                },
                component: () => import ('../views/Lamp/index')
            },
            {
                path: '/Prize/index',
                meta: {
                    title: '奖品管理系统'
                },
                component: () => import ('../views/Prize/index')
            },
        ]
    },

    {
        path: "/login",
        name: "Login",
        meta: {
            title: '登录'
        },
        component: () => import (
            "../views/Login.vue")
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    const role = localStorage.getItem('UserInfo');
    // this.$router.push("/");
    if (!role && to.path !== '/login') {
        next('/login');
    } else if (to.meta.permission) {
        // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
        role === 'admin'
            ? next()
            : next('/403');
    } else {
        next();
    }
});

export default router;
