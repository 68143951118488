<template>
  <el-dialog v-model="view" :close-on-click-modal="false" :close-on-press-escape="false" title="添加幸运数字" width="500px">
    <el-form ref="form" label-width="90px">
      <el-form-item label="幸运数字">
        <el-input type="text" v-model="form.Number"/>
      </el-form-item>
      <el-form-item label="图片编号">
        <el-select v-model="form.ImageID" placeholder="请选择" @change="selectImageID">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="图片">
        <el-image
            class="avatar"
            v-if="form.Image"
            :src="form.Image"
            :preview-src-list="[form.Image]"></el-image>
      </el-form-item>
      <el-form-item label="奖励（元)">
        <el-input type="text" v-model="form.Money"/>
      </el-form-item>
    </el-form>
    <template #footer>
          <span class="dialog-footer">
           <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="saveAdd">确定</el-button>
          </span>
    </template>
  </el-dialog>
</template>

<script>
import {findImage, AddNumbers} from "../../api/Prize";

export default {
  data() {
    return {
      options: [],
      optionsData: [],
      view: false,
      form: {
        Number: '',
        ImageID: '',
        Image: '',
        Money: ''
      }
    }
  },
  methods: {
    selectImageID(value) {
      let optionsData = this.optionsData
      for (let i in optionsData) {
        if (optionsData[i].ImageID == value) {
          this.form.Image = optionsData[i].Image
          break;
        }
      }
    },
    init() {
      this.view = true
      findImage().then(res => {
        let data = res.data
        let add = []
        for (let i in data) {
          add.push({
            value: data[i].ImageID,
            label: data[i].ImageID
          })
        }
        this.options = add
        this.optionsData = data
      })

    },
    close() {
      this.view = false
      this.form.Number = ''
      this.form.ImageID = ''
      this.form.Money = ''
      this.form.Image = ''
    },
    saveAdd() {
      let Number = this.form.Number
      let ImageID = this.form.ImageID
      let Money = this.form.Money
      AddNumbers({Number, ImageID, Money}).then(res => {
        if (res.code == 0) {
          this.$parent.fachdata()
          this.close()
          this.$message.success('添加成功');
        } else {
          this.$message.error('添加失败');
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
