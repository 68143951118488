import request from '../utils/request';

export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL+'admin/PermissionRouter/find',
        method: 'get',
        params: query
    });
};

export const NumberData = query => {
    return request({
        url: process.env.VUE_APP_URL+'admin/PermissionRouter/role/find',
        method: 'get',
        params: query
    });
};


export const SaveData = query => {
    return request({
        url: process.env.VUE_APP_URL+'admin/PermissionRouter/save',
        method: 'post',
        data: query
    });
};


export const deleteData = query => {
    return request({
        url: process.env.VUE_APP_URL+'admin/PermissionRouter/delete',
        method: 'post',
        data: query
    });
};


export const AddData = query => {
    return request({
        url: process.env.VUE_APP_URL+'admin/PermissionRouter/add',
        method: 'post',
        data: query
    });
};


export const UpData = query => {
    return request({
        url: process.env.VUE_APP_URL+'admin/PermissionRouter/up',
        method: 'post',
        data: query
    });
};
