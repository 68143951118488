import request from '../utils/request';



//获取权限
export const SiderData = query => {
    return request({
        url: process.env.VUE_APP_URL+'Authority/find',
        method: 'get',
        params: query
    });
};

//根据角色生成匹配树
export const roleData = query => {
    return request({
        url: process.env.VUE_APP_URL+'admin/PermissionRouter/role/Name',
        method: 'get',
        params: query
    });
};
