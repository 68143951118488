<template>
  <div>
    <el-dialog v-model="view.time" :close-on-click-modal="false" :close-on-press-escape="false" width="500px"
               title="设置时间">
      <el-form ref="form" label-width="100px" size="mini">
        <el-form-item label="终止日期">
          <input type="datetime-local" v-model="from.timeDate"/>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="view.time = false" size="small">取 消</el-button>
            <el-button @click="setdata" size="small">确 定</el-button>
          </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {operate, timeEnd} from '../../api/CanvasAdmin'

export default {
  data() {
    return {
      loading: false,
      view: {
        time: false,
      },
      from: {
        titleNumber: '',
        time: '',
        timeDate: ''
      }
    }
  },
  methods: {
    setdata() {
      let titleNumber = this.from.titleNumber
      let Data = this.from.timeDate
      let timeYear = new Date(Data).getFullYear(),//年
          timeMonth = new Date(Data).getMonth() + 1,//月份
          timeDate = new Date(Data).getDate(),//日
          timeHours = new Date(Data).getHours(),//时
          timeMinutes = new Date(Data).getMinutes();  //分
      if (timeMonth < 10) {
        timeMonth = '0' + timeMonth
      }
      if (timeDate < 10) {
        timeDate = '0' + timeDate
      }
      if (timeHours < 10) {
        timeHours = '0' + timeHours
      }
      if (timeMinutes < 10) {
        timeMinutes = '0' + timeMinutes
      }
      let endDate = timeYear + '/' + timeMonth + '/' + timeDate + ' ' + timeHours + ':' + timeMinutes + ':00';

      timeEnd({titleNumber, endDate}).then(res => {
        if (res.code == 0) {
          this.$message.success(res.message)
          this.closeView()
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    setshow(_id) {
      this.$confirm("确定要执行该操作吗？", "提示", {
        type: "warning",
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        operate({_id}).then(res => {
          if (res.code == 0) {
            this.getData();
            this.$message.success(res.message)
          } else {
            this.$message.warning(res.message)
          }
        })
      })
    },
    closeView() {
      this.view.time = false
      this.from.titleNumber = ''
      this.from.time = ''
      this.from.timeDate = ''
    },
    openTime(titleNumber, time) {
      let timeYear = new Date(time).getFullYear(),//年
          timeMonth = new Date(time).getMonth() + 1,//月份
          timeDate = new Date(time).getDate(),//日
          timeHours = new Date(time).getHours(),//时
          timeMinutes = new Date(time).getMinutes();  //分
      let times = timeYear + '-' + timeMonth + '-' + timeDate + 'T' + timeHours + ':' + timeMinutes

      this.from.timeDate = times

      this.from.titleNumber = titleNumber
      this.view.time = true
    },
  }
}
</script>

<style scoped lang="scss">
.avatar {
  width: 80px;
}

.handle-box {
  margin-bottom: 10px;
}

.green {
  color: #00a854;
}

.yellow {
  color: rgba(196, 154, 12, 0.81);
}
</style>
