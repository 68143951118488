<template>
  <div>
    <el-dialog v-model="view" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <el-form ref="form" label-width="50px" size="mini">
        <el-form-item label="总图">
          <el-image
              class="avatar"
              v-if="from.imageUrl"
              :src="from.imageUrl"
              :preview-src-list="[from.imageUrl]">
          </el-image>
          <p class="green" v-if="from.imageUrl && !start"><i class="el-icon-circle-check"></i>上传成功</p>
          <el-upload
              v-if="!start"
              class="upload-block"
              accept="image/png,image/jpg,image/jpeg"
              :action='actionUrl'
              :show-file-list="false"
              :on-success="handleOrdSuccess"
              :before-upload="beforeOrdUpload">
            <el-button type="default">上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="昵称" v-if="!start">
          <el-input v-model="from.name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="success" plain @click="chainHland" v-if="!start">上链</el-button>
          <el-button v-if="start" @click="openConfirmation">电子确认函</el-button>
          <el-button v-if="start" @click="openDetailUrl">证据详情</el-button>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="close" size="small">关闭</el-button>
            <el-button @click="noChina" size="small" type="danger" v-if="start">撤销上链</el-button>
              <el-button @click="test" size="small">测试</el-button>
          </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>

import {Actions, chainSet, chainSetFind, chainDetailUrl, chainConfirmation, chainNot} from "../../api/CanvasAdmin";

export default {
  data() {
    return {
      view: false,
      titleNumber: '',
      from: {},
      actionUrl: '',
      start: false,
      china: {}
    }
  },
  mounted() {
    this.actionUrl = Actions()
  },
  components: {},
  methods: {
    openConfirmation() {
      let china = this.china
      let evidenceId = china.evidenceId
      chainConfirmation({evidenceId}).then(res => {
        let url = res.url
        window.open(url)
      })
    },
    openDetailUrl() {
      let china = this.china
      let evidenceId = china.evidenceId
      chainDetailUrl({evidenceId}).then(res => {
        let url = res.url
        window.open(url)
      })
    },
    close() {
      this.from = {}
      this.start = false
      this.view = false
    },
    handleOrdSuccess(res) {
      this.from.imageUrl = res.imgfile;
      this.from.imageName = res.name;
    },
    beforeOrdUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 500;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 500MB!');
      }
      return isJPG && isLt2M;
    },
    test() {
      console.log(this.from)
    },
    open(titleNumber) {
      this.titleNumber = titleNumber
      this.view = true
      this.find(titleNumber)
    },
    noChina() {
      let titleNumber = this.titleNumber
      chainNot({titleNumber}).then(res => {
        if (res.code == 0) {
          this.$message.success(res.message);
          this.close()
        } else {
          this.$message.error(res.message);
        }
      })
    },
    find(titleNumber) {
      chainSetFind({titleNumber}).then(res => {
        if (res.code == 0) {
          let imgfile = res.data.imgfile
          this.from.imageUrl = imgfile
          this.start = true
          this.china = res.data
        } else {
          this.start = false
        }
      })
    },
    chainHland() {
      let titleNumber = this.titleNumber
      let imageUrl = this.from.imageUrl
      let imageName = this.from.imageName
      let name = this.from.name
      if (imageUrl && name && imageName) {
        chainSet({imageUrl, titleNumber, name, imageName}).then(res => {
          if (res.code == 0) {
            this.$message.success(res.message);
            this.view = false
            this.from = {}
          } else {
            this.$message.error(res.message);
          }
        })
      } else {
        this.$message.error('缺少必填项');
      }
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  width: 900px;
  height: 500px;
  margin: 0 auto;
  overflow: auto;

}

.green {
  color: #00a854;
}


.upload-block {
  ::v-deep .el-upload--text {
    width: auto;
    height: auto;
    border: none;
  }
}
</style>
