import request from '../utils/request';

export const find = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Prize/admin/find',
        method: 'get',
        params: query
    });
};


export const AddNumbers = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Prize/admin/AddNumbers',
        method: 'post',
        data: query
    });
};

export const removeNumbers = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Prize/admin/remove',
        method: 'post',
        data: query
    });
};


export const findImage = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Prize/admin/ImageAdmin/find',
        method: 'get',
        params: query
    });
};

export const AddImage = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Prize/admin/ImageAdmin/add',
        method: 'post',
        data: query
    });
};

export const removeImage = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Prize/admin/ImageAdmin/remove',
        method: 'post',
        data: query
    });
};


export const findUser = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Prize/admin/findUser',
        method: 'get',
        params: query
    });
};

export const finddown = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Prize/admin/findUser/down',
        method: 'get',
        params: query
    });
};









