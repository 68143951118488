import request from '../utils/request';

//查询画布列表
export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'CanvasAdmin/find',
        method: 'get',
        params: query
    });
};

//查询创作列表
export const listData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'CanvasAdmin/find/list',
        method: 'get',
        params: query
    });
};

// 删除画布
export const deleteData = id => {
    return request({
        url: process.env.VUE_APP_URL + 'CanvasAdmin/remove/' + id,
        method: 'delete',
    });
};

//配置新画布
export const initData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'CanvasAdmin/init',
        method: 'post',
        data: query
    });
};


//启用画布
export const openCanvas = query => {
    return request({
        url: process.env.VUE_APP_URL + 'CanvasAdmin/open',
        method: 'post',
        data: query
    });
};


//上传图片地址
export const Actions = () => {
    return process.env.VUE_APP_URL+'image/adds'
};


export const Action = () => {
    return process.env.VUE_APP_URL+'image/add'
};




export const operate = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'CanvasAdmin/operate',
        method: 'post',
        data: query
    });
};


//画布设置终止时间
export const timeEnd = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'CanvasAdmin/timeEnd',
        method: 'post',
        data: query
    });
};

//画布总集
export const listTol = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'CanvasAdmin/find/listTol',
        method: 'get',
        params: query
    });
};

export const base64Img = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "image/base64Img",
        method: "post",
        data: query,
    });
};


//设置画布生成效果图
export const genBgUrlset = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "CanvasAdmin/genBgUrl/set",
        method: "post",
        data: query,
    });
};

export const genBgUrlfind = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "CanvasAdmin/genBgUrl/find",
        method: "get",
        params: query,
    });
};




//上链
export const chainSet = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "CanvasAdmin/Chain/set",
        method: "post",
        data: query,
    });
};

export const chainSetFind = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "CanvasAdmin/Chain/find",
        method: "get",
        params: query,
    });
};

export const chainDetailUrl = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "CanvasAdmin/Chain/DetailUrl",
        method: "get",
        params: query,
    });
};

export const chainConfirmation = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "CanvasAdmin/Chain/Confirmation",
        method: "get",
        params: query,
    });
};


export const chainNot = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "CanvasAdmin/Chain/not",
        method: "post",
        data: query,
    });
};


