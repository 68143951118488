import request from '../utils/request';

export const find = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Lamp/admin/find',
        method: 'get',
        params: query
    });
};

export const adds = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Lamp/admin/add',
        method: 'post',
        data: query
    });
};

export const removes = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Lamp/admin/remove',
        method: 'post',
        data: query
    });
};



