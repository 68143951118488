<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i>奖品管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="container">
      <div class="handle-box">
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">添加幸运数字</el-button>
        <el-button type="primary" icon="el-icon-s-tools" @click="openSetup">奖品设置</el-button>
        <el-button type="primary" icon="el-icon-s-claim" @click="openUser">用户中奖信息</el-button>
      </div>
      <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          size="small"
          height="500"
      >
        <el-table-column prop="titleNumber" label="项目编号"></el-table-column>
        <el-table-column prop="Number" label="幸运数字"></el-table-column>
        <el-table-column prop="ImageID" label="图片编号"></el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-image
                class="Image"
                :src="scope.row.Image"
                :preview-src-list="[scope.row.Image]">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="Money" label="金额"></el-table-column>
        <el-table-column prop="NewTime" label="新增时间"></el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-button
                type="text"
                class="red"
                @click="removePrize( scope.row.id)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <ImageAdmin ref="ImageAdmin"></ImageAdmin>
    <Add ref="Add"></Add>
    <User ref="User"></User>
  </div>
</template>

<script>
import {find, findImage, removeNumbers} from '../../api/Prize'
import ImageAdmin from './ImageAdmin'
import Add from './add'
import User from './user'

export default {
  data() {
    return {
      tableData: [],
      ImageData: []
    };
  },
  created() {
    findImage().then(res => {
      this.ImageData = res.data
      this.fachdata()
    })

  },
  methods: {
    openUser(){
      this.$refs.User.open()
    },
    openSetup() {
      this.$refs.ImageAdmin.open()
    },
    fachdata() {
      find().then(res => {
        let data = res.data
        let ImageData = this.ImageData
        for (let i in data) {
          let ImageID = data[i].ImageID
          for (let k in ImageData) {
            if (ImageData[k].ImageID == ImageID) {
              data[i].Image = ImageData[k].Image
              break;
            }
          }
        }
        this.tableData = data
      })
    },
    handleAdd() {
      this.$refs.Add.init()
    },
    removePrize(id) {
      this.$confirm("是否确认删除吗？", "提示", {
        type: "warning"
      }).then(() => {
        removeNumbers({id}).then(res => {
          if (res.code == 0) {
            this.fachdata()
            this.$message.success('删除成功');
          } else {
            this.$message.error('删除失败');
          }
        })

      })
    }
  },
  components: {
    ImageAdmin,
    Add,
    User
  }

};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.Image {
  width: 40px;
}


.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

</style>
