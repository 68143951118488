<template>
  <div>
    <el-dialog v-model="view" :close-on-click-modal="false" :close-on-press-escape="false" title="用户中奖信息" width="900px">
      <el-table
          :data="tableData"
          border
          class="table"
          header-cell-class-name="table-header"
          size="small"
          height="500"
      >
        <el-table-column prop="titleNumber" label="项目编号"></el-table-column>
        <el-table-column prop="Number" label="幸运数字"></el-table-column>
        <el-table-column prop="ImageID" label="图片编号"></el-table-column>
        <el-table-column prop="Name" label="笔名"></el-table-column>
        <el-table-column prop="Money" label="金额"></el-table-column>
        <el-table-column prop="Contact" label="联系方式"></el-table-column>
        <el-table-column prop="NewTime" label="中奖时间"></el-table-column>
      </el-table>
      <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="down">下载</el-button>
          </span>
      </template>

    </el-dialog>
  </div>
</template>

<script>
import {findUser, finddown} from "../../api/Prize";

export default {
  data() {
    return {
      view: false,
      tableData: []
    }
  },
  methods: {
    down() {
      finddown().then(res => {
        let file = res.file
        window.open(process.env.VUE_APP_URL  + file)

        // process.env.VUE_APP_URL
      })
    },
    open() {
      this.view = true
      this.fachdata()
    },
    fachdata() {
      findUser().then(res => {
        this.tableData = res.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
.add {
  margin-bottom: 20px;
}

.red {
  color: red;
}

.Image {
  width: 80px;
}

.upload-block {
  ::v-deep .el-upload--text {
    width: auto;
    height: auto;
    border: none;
  }
}
</style>
