import request from '../utils/request';

export const fetchData = query => {
    return request({
            url:process.env.VUE_APP_URL+ 'admin/comment/find',
        method: 'get',
        params: query
    });
};

export const operate = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'admin/comment/operate',
        method: 'post',
        data: query
    });
};

export const remove = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'admin/comment/remove',
        method: 'post',
        data: query
    });
};



